import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../color/color";
import { axiosInstance } from "../../constants/axiosInterceptor";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const Entrata = () => {
  const [viewProperties, setViewProperties] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState(undefined);
  const errmsg = useRef();
  const navigate = useNavigate();
  const toast = useToast({
    duration: 2000,
    position: "top-right",
    isClosable: true,
  });

  useEffect(() => {
    loadProperties();
  }, []);

  const loadProperties = () => {
    axiosInstance
      .get("/admin/property/view-properties", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setViewProperties(response.data);
      });
  };

  const handleSubmit = (values) => {
    if (!selectedPropertyId) {
      errmsg.current.style.visibility = "visible";
    } else {
      errmsg.current.style.visibility = "hidden";
    }
    const BODY = {
      propertyPartnerId: selectedPropertyId.value,
      firstName: values.fname,
      lastName: values.lname,
      email: values.email,
      number: values.phoneNumber,
    };
    axiosInstance
      .put("/admin/property/post-to-entrata-from-fe", BODY)
      .then((res) => {
        console.log("sucess", res);
        toast({
          title: "Successful!",
          description: "Entrata has been notified.",
          status: "success",
        });
        navigate("/viewallproperties");
      })
      .catch((err) => console.error(err.message));
  };
  return (
    <>
      <Box pt="10px" bg={COLORS.greyEAEAEA} p="8" minH="82.3vh">
        <Stack spacing="5" pl="2">
          <Formik
            initialValues={{
              propertyPartnerId: "",
              fname: "",
              lname: "",
              email: "",
              phoneNumber: "",
            }}
            onSubmit={handleSubmit}
          >
            {(formikProps) => {
              return (
                <>
                  <Box bg={COLORS.white} p="7" borderRadius="6">
                    <Form>
                      <HStack pb="3" w="85%" spacing="5">
                        <FormControl>
                          <FormLabel fontSize="20px">First Name</FormLabel>
                          <Input
                            placeholder="First Name"
                            borderRadius="6"
                            variant="outline"
                            type="text"
                            name="fname"
                            required
                            onChange={(e) =>
                              formikProps.setFieldValue(`fname`, e.target.value)
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel fontSize="20px">Last Name</FormLabel>
                          <Input
                            placeholder="Last Name"
                            borderRadius="6"
                            variant="outline"
                            type="text"
                            name="lname"
                            required
                            onChange={(e) =>
                              formikProps.setFieldValue(`lname`, e.target.value)
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          />
                        </FormControl>
                      </HStack>
                      <HStack pb="3" w="85%" spacing="5">
                        <FormControl>
                          <FormLabel fontSize="20px">Email</FormLabel>
                          <Input
                            placeholder="Email"
                            borderRadius="6"
                            variant="outline"
                            type="text"
                            name="email"
                            required
                            onChange={(e) =>
                              formikProps.setFieldValue(`email`, e.target.value)
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel fontSize="20px">Phone number</FormLabel>
                          <Input
                            placeholder="Phone number"
                            borderRadius="6"
                            variant="outline"
                            type="text"
                            name="phoneNumber"
                            required
                            onChange={(e) =>
                              formikProps.setFieldValue(
                                `phoneNumber`,
                                e.target.value
                              )
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          />
                        </FormControl>
                      </HStack>

                      <Text fontSize="20px" pb="5">
                        Property Partner Id
                      </Text>
                      <Stack w="45%" pb="4">
                        <Select
                          borderRadius="6"
                          bg={COLORS.greyEAEAEA}
                          isReq
                          value={selectedPropertyId}
                          options={viewProperties.map((item) => {
                            return {
                              label: item.complexName,
                              value: item.id,
                            };
                          })}
                          onChange={(option) => {
                            setSelectedPropertyId(option);
                            errmsg.current.style.visibility = option
                              ? "hidden"
                              : "visible";
                          }}
                        />

                        <Box
                          visibility="hidden"
                          ref={errmsg}
                          fontSize="15px"
                          color="red"
                        >
                          <span style={{ color: "red" }}>* </span>
                          required
                        </Box>
                      </Stack>
                      <Box pb="5" py="4" pt="50px">
                        <Button
                          type="submit"
                          bg={COLORS.orange}
                          color={COLORS.white}
                          _hover={{
                            bg: COLORS.orange,
                          }}
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  </Box>
                </>
              );
            }}
          </Formik>
        </Stack>
      </Box>
    </>
  );
};

export default Entrata;
