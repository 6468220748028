import React from "react";
import { Text, Checkbox, HStack, CheckboxGroup } from "@chakra-ui/react";
import { useFormikContext } from "formik";

const CheckboxSection = ({ isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <Text fontSize="22px" fontWeight="bold">
        Amenities
      </Text>
      <CheckboxGroup colorScheme="orange" size="lg" name="amenities">
        <HStack spacing="10">
          <Checkbox
            isDisabled={isDisabled}
            name="parking"
            type="checkbox"
            isChecked={values?.parking}
            onChange={(e) => {
              setFieldValue("parking", e.target.checked);
            }}
          >
            Parking
          </Checkbox>
          <Checkbox
            isDisabled={isDisabled}
            name="privateEntrance"
            type="checkbox"
            isChecked={values?.privateEntrance}
            onChange={(e) => {
              setFieldValue("privateEntrance", e.target.checked);
            }}
          >
            Private Entrance
          </Checkbox>

          <Checkbox
            isDisabled={isDisabled}
            name="gym"
            type="checkbox"
            isChecked={values?.gym}
            onChange={(e) => {
              setFieldValue("gym", e.target.checked);
            }}
          >
            GYM
          </Checkbox>

          <Checkbox
            name="adaAccessible"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.adaAccessible}
            onChange={(e) => {
              setFieldValue("adaAccessible", e.target.checked);
            }}
          >
            ADA Accessible
          </Checkbox>
        </HStack>
        <HStack spacing="5">
          <Checkbox
            name="doorMan"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.doorMan}
            onChange={(e) => {
              setFieldValue("doorMan", e.target.checked);
            }}
          >
            Door Man
          </Checkbox>
          <Checkbox
            name="pool"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.pool}
            onChange={(e) => {
              setFieldValue("pool", e.target.checked);
            }}
          >
            Pool
          </Checkbox>
          <Checkbox
            name="privateBathroom"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.privateBathroom}
            onChange={(e) => {
              setFieldValue("privateBathroom", e.target.checked);
            }}
          >
            Private Bathroom
          </Checkbox>
          <Checkbox
            name="wifi"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.wifi}
            onChange={(e) => {
              setFieldValue("wifi", e.target.checked);
            }}
          >
            WiFi
          </Checkbox>
        </HStack>
        <HStack spacing="5">
          <Checkbox
            name="ac"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.ac}
            onChange={(e) => {
              setFieldValue("ac", e.target.checked);
            }}
          >
            AC
          </Checkbox>
          <Checkbox
            name="privateLaundry"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.privateLaundry}
            onChange={(e) => {
              setFieldValue("privateLaundry", e.target.checked);
            }}
          >
            Private Laundary
          </Checkbox>
          <Checkbox
            name="furnished"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.furnished}
            onChange={(e) => {
              setFieldValue("furnished", e.target.checked);
            }}
          >
            Furnished
          </Checkbox>
          <Checkbox
            name="privateCloset"
            type="checkbox"
            isDisabled={isDisabled}
            isChecked={values?.privateCloset}
            onChange={(e) => {
              setFieldValue("privateCloset", e.target.checked);
            }}
          >
            Private Closet
          </Checkbox>
        </HStack>
      </CheckboxGroup>

      <Text fontSize="22px" fontWeight="bold">
        Home Preferences
      </Text>
      <CheckboxGroup colorScheme="orange" size="lg" id="homePref">
        <HStack spacing="5">
          <Checkbox
            type="checkbox"
            name="smoke"
            isDisabled={isDisabled}
            isChecked={values?.smoke}
            onChange={(e) => {
              setFieldValue("smoke", e.target.checked);
            }}
          >
            Smoke
          </Checkbox>
          <Checkbox
            type="checkbox"
            name="pets"
            isDisabled={isDisabled}
            isChecked={values?.pets}
            onChange={(e) => {
              setFieldValue("pets", e.target.checked);
            }}
          >
            Pet Friendly
          </Checkbox>
        </HStack>
      </CheckboxGroup>
    </>
  );
};

export default CheckboxSection;
