import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { axiosInstance } from "../../constants/axiosInterceptor";
import { storage } from "../../firebase";

export default function useAddProperty() {
  const [locationInfo, setLocationInfo] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    lat: "",
    lng: "",
  });
  const [imageFromFirebase, setImageFromFirebase] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectCategories, setSelectCategories] = useState([]);
  const navigate = useNavigate();
  const toast = useToast({
    duration: 2000,
    position: "top-right",
    isClosable: true,
  });

  const handleSubmit = (values) => {
    const BODY = {
      propertyPartnerCategories: selectCategories.map((item) => item.value),
      complexName: values.name,
      minPrice: values.minPrice,
      maxPrice: values.maxPrice,
      parkingFee: values.parkingFee,
      description: values.description,
      location: {
        address: locationInfo.address,
        city: locationInfo.city,
        country: locationInfo.country,
        state: locationInfo.state,
        longitude: locationInfo.lng,
        latitude: locationInfo.lat,
      },
      propertyPartnerAmenities: {
        parking: values.parking,
        privateEntrance: values.privateEntrance,
        gym: values.gym,
        adaAccessible: values.adaAccessible,
        doorMan: values.doorMan,
        pool: values.pool,
        privateBathroom: values.privateBathroom,
        wifi: values.wifi,
        ac: values.ac,
        privateLaundry: values.privateLaundry,
        furnished: values.furnished,
        privateCloset: values.privateCloset,
      },
      pets: values.pets ? 1 : 0,
      smoke: values.smoke ? 1 : 0,
      listedPropertyImages: imageFromFirebase,
    };

    axiosInstance
      .post("/admin/property/add-property-partner", BODY, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast({
          title: "Successful!",
          description: "Property has been Added.",
          status: "success",
        });
        navigate("/viewallproperties");
      })
      .catch((err) => console.error(err.message));
  };

  return {
    setLocationInfo,
    locationInfo,
    setImageFromFirebase,
    imageFromFirebase,
    handleSubmit,
    setCategories,
    categories,
    selectCategories,
    setSelectCategories,
  };
}
