import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthPage } from "./auth/AuthPage";
import { PostPlace } from "./components/AddProperty/AddProperty";
import ViewAllProperties from "./components/ViewAllProperties/ViewAllProperties";
import { EditProperty } from "./components/EditProperty/EditProperty";
import Notification from "./components/Notification/Notification";
import Layout from "./components/Layout/Layout";
import Entrata from "./components/Entrata/Entrata";

function App() {
  return (
    // <Router>
    <Routes>
      <Route path="/" element={<AuthPage />} />
      <Route path="/" element={<Layout />}>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/propertypartner"
            element={
              <>
                <Helmet>
                  <title>New Property</title>
                </Helmet>
                <PostPlace />
              </>
            }
          />
          <Route
            path="viewallproperties"
            element={
              <>
                <Helmet>
                  <title>View all Property</title>
                </Helmet>
                <ViewAllProperties />
              </>
            }
          />
          <Route
            path="viewproperty"
            element={
              <>
                <Helmet>
                  <title>View Property</title>
                </Helmet>
                <EditProperty />
              </>
            }
          />
          <Route
            exact
            path="editproperty"
            element={
              <>
                <Helmet>
                  <title>Edit Property</title>
                </Helmet>
                <EditProperty />
              </>
            }
          />
          <Route
            exact
            path="Notification"
            element={
              <>
                <Helmet>
                  <title>Notification</title>
                </Helmet>
                <Notification />
              </>
            }
          />
          <Route
            exact
            path="Entrata"
            element={
              <>
                <Helmet>
                  <title>Entrata</title>
                </Helmet>
                <Entrata />
              </>
            }
          />
        </Route>
      </Route>
    </Routes>
    // </Router>
  );
}

export default App;
