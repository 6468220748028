import axios from "axios";
import { useUserStore } from "../store/user";
import { useEffect } from "react";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = config.headers;
    if (localStorage.getItem("access_token")) {
      const accessToken = localStorage.getItem("access_token");
      const token = JSON.parse(accessToken);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;

    // function (error: any) {
    //   return Promise.reject(error);
    // }
    // (config) => {
    //   const { user } = useUserStore();
    //   const contentType = "application/json";
    //   config.headers = {
    //     "Content-Type": config.headers["Content-Type"] || contentType,
    //     Authorization:
    //       config.headers.Authorization || "Bearer " + user["data"].data.token,
    //   };
    //   return config;

    // const accessToken = !!localStorage.getItem("accessToken")
    //   ? localStorage.getItem("accessToken")
    //   : null;
    // if (!!accessToken)
    //   config.headers = {
    //     Authorization: `Bearer ${accessToken}`,
    //   };
    // return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const AxiosInterceptor = ({ children }) => {
  // const { logoutHandler } = useLogout();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, []);

  return children;
};
export { axiosInstance, AxiosInterceptor };

// axiosInstance.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     console.log(error);
//     if (error.response.status === 403) {
//       localStorage.removeItem("token");
//       window.location.href = "/";
//     }
//     return Promise.reject(error);
//   }
// );
