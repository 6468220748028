// Import the functions you need from the SDKs you need
// import firebase /* { initializeApp } */ from "firebase/compact/app";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNShNwuC21ajrP6ysL05grk1FGyvjOSyM",
  authDomain: "chillow-302106.firebaseapp.com",
  projectId: "chillow-302106",
  storageBucket: "chillow-302106.appspot.com",
  messagingSenderId: "174269104238",
  appId: "1:174269104238:web:9eb2e90ffcd56555a5a67f",
  measurementId: "G-VP4NJFPMJT",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const db = getFirestore(app);
// const analytics = getAnalytics(app);

// export default analytics;
export { storage, db, firebase as default };
