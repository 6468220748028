import { Input } from "@chakra-ui/react";
import React from "react";
import { storage } from "../../firebase";

const UploadImage = ({ imageFromFirebase, setImageFromFirebase }) => {
  const handleFireBaseUpload = (e) => {
    if (imageFromFirebase.length) {
      alert("Image already uploaded.");
      return;
    }
    const image = e.target.files[0];
    const uploadTask = storage.ref(`Admin/images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log("snapshot after upload: ", snapShot);
      },
      (err) => {
        console.log("Error while uploading: ", err);
      },
      () => {
        storage
          .ref("Admin/images")
          .child(image.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageFromFirebase(() => [
              ...imageFromFirebase,
              {
                file: fireBaseUrl,
              },
            ]);
          });
      }
    );
  };

  return (
    <div>
      <label
        htmlFor="files"
        className="btn"
        style={{
          border: "1px dotted",
          padding: "10px",
          borderRadius: "12px",
          cursor: "pointer",
        }}
      >
        Add photos
      </label>

      <Input
        id="files"
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        onChange={handleFireBaseUpload}
      />
    </div>
  );
};

export default UploadImage;
