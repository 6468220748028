import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Checkbox,
  Stack,
  HStack,
  CheckboxGroup,
  Textarea,
  Button,
  NumberInputField,
  NumberInput,
  Image,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { COLORS } from "../../color/color";
import AutoComplete from "./../AutoComplete/Autocomplete";
import { axiosInstance } from "../../constants/axiosInterceptor";
import UploadImage from "../../components/UploadImage/UploadImage";
import useAddProperty from "./useAddProperty";
import Select from "react-select";
import { SmallCloseIcon } from "@chakra-ui/icons";

export const PostPlace = () => {
  const {
    imageFromFirebase,
    setImageFromFirebase,
    setLocationInfo,
    handleSubmit,
    setCategories,
    categories,
    setSelectCategories,
  } = useAddProperty();

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = () => {
    axiosInstance
      .get("/admin/property/get-property-types", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCategories(response.data);
      });
  };
  const imageDelete = (e) => {
    // let pictureRef = storage.refFromURL(url);
    // pictureRef
    //   .delete()
    //   .then(() => {
    //     // setImageFromFirebase(
    //     //   imageFromFirebase.filter((image) => image !== url)
    //     // );

    //     alert("Picture is deleted successfully!");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    const s = imageFromFirebase.filter((item, index) => index !== e);
    setImageFromFirebase(s);
  };
  return (
    <Box pt="10px" bg={COLORS.greyEAEAEA} p="8">
      <Stack spacing="5" pl="2">
        <Formik
          initialValues={{
            propertyPartnerCategories: [],
            name: "",
            minPrice: "",
            maxPrice: "",
            parkingFee: "",
            description: "",
            parking: false,
            privateEntrance: false,
            gym: false,
            adaAccessible: false,
            doorMan: false,
            pool: false,
            privateBathroom: false,
            wifi: false,
            ac: false,
            privateLaundry: false,
            furnished: false,
            privateCloset: false,
            pets: false,
            smoke: false,
          }}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            return (
              <>
                <Box bg={COLORS.white} p="7" borderRadius="6">
                  <Form>
                    <Text fontWeight="bold" fontSize="22px" pb="5">
                      Add Property Partner
                    </Text>
                    <Stack w="45%" pb="4">
                      <Select
                        borderRadius="6"
                        bg={COLORS.greyEAEAEA}
                        isMulti
                        isReq
                        options={categories.map((item) => {
                          return {
                            label: item.typeName,
                            value: item.id,
                          };
                        })}
                        onChange={setSelectCategories}
                      />
                    </Stack>
                    <HStack pb="3" w="85%" spacing="5">
                      <FormControl>
                        <FormLabel fontSize="20px">Property Name</FormLabel>
                        <Input
                          placeholder="Property Name"
                          borderRadius="6"
                          variant="outline"
                          type="text"
                          name="name"
                          required
                          onChange={(e) =>
                            formikProps.setFieldValue(`name`, e.target.value)
                          }
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontSize="20px">Minimum Price</FormLabel>
                        <NumberInput
                          borderRadius="6"
                          name="minPrice"
                          variant="outline"
                          required
                          min={1}
                          onKeyDown={(e) =>
                            (e.key === "." || e.key === "e" || e.key === "-") &&
                            e.preventDefault()
                          }
                          onChange={(value) =>
                            formikProps.setFieldValue(`minPrice`, +value)
                          }
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        >
                          <NumberInputField placeholder="Minimum Price" />
                        </NumberInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel fontSize="20px">Maximum Price</FormLabel>
                        <NumberInput
                          borderRadius="6"
                          // bg={COLORS.greyEAEAEA}
                          variant="outline"
                          name="maxPrice"
                          required
                          min={1}
                          onKeyDown={(e) =>
                            (e.key === "." || e.key === "e" || e.key === "-") &&
                            e.preventDefault()
                          }
                          onChange={(value) =>
                            formikProps.setFieldValue(`maxPrice`, +value)
                          }
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        >
                          <NumberInputField placeholder="Maximum Price" />
                        </NumberInput>
                      </FormControl>
                    </HStack>
                    <HStack spacing="5" w="25%">
                      <FormControl>
                        <FormLabel fontSize="20px">Parking Fee</FormLabel>
                        <NumberInput
                          variant="outline"
                          borderRadius="6"
                          name="parkingFee"
                          required
                          min={1}
                          onKeyDown={(e) =>
                            (e.key === "." || e.key === "e" || e.key === "-") &&
                            e.preventDefault()
                          }
                          onChange={(value) =>
                            formikProps.setFieldValue(`parkingFee`, +value)
                          }
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        >
                          <NumberInputField placeholder="Parking Fee" />
                        </NumberInput>
                      </FormControl>
                    </HStack>
                    <HStack w="55%" pt="4">
                      <FormControl>
                        <FormLabel fontSize="20px">About</FormLabel>
                        <Textarea
                          name="description"
                          borderRadius="6"
                          placeholder="About Property"
                          onChange={(e) =>
                            formikProps.setFieldValue(
                              `description`,
                              e.target.value
                            )
                          }
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        />
                      </FormControl>
                    </HStack>

                    <AutoComplete setlocation={setLocationInfo} />

                    <Stack spacing="5">
                      <Text fontSize="20px" pt="3" fontWeight="bold">
                        Amenities
                      </Text>
                      <CheckboxGroup
                        colorScheme="orange"
                        size="lg"
                        name="amenities"
                      >
                        <HStack spacing="10">
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="parking"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Parking
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="privateEntrance"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Private Entrance
                          </Checkbox>

                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="gym"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            GYM
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="adaAccessible"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            ADA Accessible
                          </Checkbox>
                        </HStack>
                        <HStack spacing="5">
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="doorMan"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Door Man
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="pool"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Pool
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="privateBathroom"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Private Bathroom
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="wifi"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            WiFi
                          </Checkbox>
                        </HStack>
                        <HStack spacing="5">
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="ac"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            AC
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="privateLaundry"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Private Laundary
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="furnished"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Furnished
                          </Checkbox>
                          <Checkbox
                            name="amenities"
                            type="checkbox"
                            value="privateCloset"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Private Closet
                          </Checkbox>
                        </HStack>
                      </CheckboxGroup>

                      <Text fontSize="20px" fontWeight="bold">
                        Home Preferences
                      </Text>
                      <CheckboxGroup
                        colorScheme="orange"
                        size="lg"
                        id="homePref"
                      >
                        <HStack spacing="5">
                          <Checkbox
                            type="checkbox"
                            name="homePref"
                            value="smoke"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Smoke
                          </Checkbox>
                          <Checkbox
                            type="checkbox"
                            name="homePref"
                            value="pets"
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                e.target.value,
                                e.target.checked
                              );
                            }}
                          >
                            Pet Friendly
                          </Checkbox>
                        </HStack>
                      </CheckboxGroup>
                      <Text fontSize="20px" fontWeight="bold" pt="5">
                        Add photos
                      </Text>
                      <Box w="30%">
                        <HStack>
                          <UploadImage
                            imageFromFirebase={imageFromFirebase}
                            setImageFromFirebase={setImageFromFirebase}
                          />
                        </HStack>
                        {imageFromFirebase.length > 0 &&
                          imageFromFirebase.map((item, keys) => {
                            return (
                              <>
                                <SmallCloseIcon
                                  onClick={() => imageDelete(keys)}
                                />
                                <Image
                                  key={keys}
                                  src={item.file}
                                  alt={`Uploaded Image ${keys}`}
                                />
                              </>
                            );
                          })}
                      </Box>
                      <Box pb="5" py="4">
                        <Button
                          type="submit"
                          bg={COLORS.orange}
                          color={COLORS.white}
                          _hover={{
                            bg: COLORS.orange,
                          }}
                          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Stack>
                  </Form>
                </Box>
              </>
            );
          }}
        </Formik>
      </Stack>
    </Box>
  );
};
