import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import { FormControl, FormLabel, Input, HStack, Box } from "@chakra-ui/react";
import { COLORS } from "../../color/color";

export function AutoComplete({
  isScriptLoaded,
  isScriptLoadSucceed,
  locationAddress,
  setlocation,
}) {
  const [address, setAddress] = useState();

  useEffect(() => {
    setAddress(locationAddress);
  }, [locationAddress]);

  const handleChange = (value) => {
    setAddress(value);
  };

  const handleSelect = async (value) => {
    setAddress(value);
    const string = value.split(",");
    let count = string.length;
    const country = string[string.length - 1];
    count = count - 1;
    const state = string[string.length - 2];
    count = count - 1;
    const city = string[string.length - 3];
    count = count - 1;

    var add = "";
    for (var i = 0; i < count; i++) {
      add = add + string[i];
    }

    geocodeByAddress(value)
      .then((results) => {
        getLatLng(results[0]).then((latLng) => {
          setlocation({
            address: add,
            city: city,
            country: country,
            state: state,
            lat: latLng.lat,
            lng: latLng.lng,
          });
        });
      })
      .catch((error) => console.error("Error", error));
  };
  const searchOptions = {
    componentRestrictions: { country: ["us"] },
  };

  if (isScriptLoaded && isScriptLoadSucceed) {
    return (
      <div>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <HStack w="35%" pt="3">
                <FormControl id="about">
                  <FormLabel fontSize="22px">Location</FormLabel>
                  <Input
                    borderRadius="6"
                    placeholder="Location"
                    required
                    {...getInputProps({})}
                  />
                </FormControl>
              </HStack>
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const style = suggestion.active
                    ? { backgroundColor: COLORS.orange, cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };

                  return (
                    <Box
                      w="35%"
                      key={i}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </Box>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}
export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_KEY}&libraries=places`,
])(AutoComplete);
