import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Stack,
  HStack,
  Textarea,
  Button,
  NumberInputField,
  NumberInput,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { COLORS } from "../../color/color";
import AutoComplete from "./../AutoComplete/Autocomplete";
import { axiosInstance } from "../../constants/axiosInterceptor";
import UploadImage from "../../components/UploadImage/UploadImage";
import { useLocation, useNavigate } from "react-router-dom";
import CheckboxSection from "./CheckboxSection";
import Select from "react-select";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { storage } from "../../firebase";

export const EditProperty = () => {
  const temp = useLocation();
  const [locationAddress, setLocationAddress] = useState();
  const [imageFromFirebase, setImageFromFirebase] = useState([]);
  const navigate = useNavigate();
  const [initialValuess, setInitialValues] = useState();
  const [image, setImage] = useState([]);
  const [locationInfo, setLocationInfo] = useState();
  const [selectCategories, setSelectCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const toast = useToast({
    duration: 2000,
    position: "top-right",
    isClosable: true,
  });

  useEffect(() => {
    let tempAdd = "";
    if (!locationInfo || !Object.keys(locationInfo).length) return;
    if (locationInfo.address !== "") {
      tempAdd = tempAdd + locationInfo.address;
    }
    if (locationInfo.city !== "") {
      tempAdd = tempAdd + ", " + locationInfo.city;
    }
    if (locationInfo.state !== "") {
      tempAdd = tempAdd + ", " + locationInfo.state;
    }
    if (locationInfo.country !== "") {
      tempAdd = tempAdd + ", " + locationInfo.country;
    }
    setLocationAddress(tempAdd);
  }, [locationInfo]);
  useEffect(() => {
    setImage(imageFromFirebase);
  }, [imageFromFirebase]);

  useEffect(() => {
    axiosInstance
      .get("/admin/property/view-properties", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const property = res.data.find((x) => x.id === temp.state.prop_id);

        setInitialValues({
          propertyPartnerCategories: property.propertyPartnerCategories,
          complexName: property.complexName,
          minPrice: property.minPrice,
          maxPrice: property.maxPrice,
          parkingFee: property.parkingFee,
          description: property.description,
          parking: property.propertyPartnerAmenities.parking,
          privateEntrance: property.propertyPartnerAmenities.privateEntrance,
          gym: property.propertyPartnerAmenities.gym,
          adaAccessible: property.propertyPartnerAmenities.adaAccessible,
          doorMan: property.propertyPartnerAmenities.doorMan,
          pool: property.propertyPartnerAmenities.pool,
          privateBathroom: property.propertyPartnerAmenities.privateBathroom,
          wifi: property.propertyPartnerAmenities.wifi,
          ac: property.propertyPartnerAmenities.ac,
          privateLaundry: property.propertyPartnerAmenities.privateLaundry,
          furnished: property.propertyPartnerAmenities.furnished,
          privateCloset: property.propertyPartnerAmenities.privateCloset,
          pets: property.pets,
          smoke: property.smoke,
        });

        setLocationInfo({
          address: !!property.location.address ? property.location.address : "",
          city: !!property.location.city ? property.location.city : "",
          state: !!property.location.state ? property.location.state : "",
          country: !!property.location.country ? property.location.country : "",
          lat: !!property.location.latitude ? property.location.latitude : "",
          lng: !!property.location.longitude ? property.location.longitude : "",
        });
        setImage(property.listedPropertyImages);
        setSelectCategories(property.propertyPartnerCategories);
      })
      .catch((err) => console.log("Get err => ", err));
  }, []);
  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = () => {
    axiosInstance
      .get("/admin/property/get-property-types", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCategories(response.data);
      });
  };

  const handleSubmit = (values) => {
    const BODY = {
      id: temp.state.prop_id,
      propertyPartnerCategories: selectCategories?.map((item) => item.value),
      complexName: values.complexName,
      description: values.description,
      minPrice: values.minPrice,
      maxPrice: values.maxPrice,
      parkingFee: values.parkingFee,
      location: {
        address: locationInfo.address,
        city: locationInfo.city,
        country: locationInfo.country,
        state: locationInfo.state,
        longitude: locationInfo.lng,
        latitude: locationInfo.lat,
      },
      propertyPartnerAmenities: {
        parking: values.parking,
        privateEntrance: values.privateEntrance,
        gym: values.gym,
        adaAccessible: values.adaAccessible,
        doorMan: values.doorMan,
        pool: values.pool,
        privateBathroom: values.privateBathroom,
        wifi: values.wifi,
        ac: values.ac,
        privateLaundry: values.privateLaundry,
        furnished: values.furnished,
        privateCloset: values.privateCloset,
      },
      pets: values.pets ? 1 : 0,
      smoke: values.smoke ? 1 : 0,
      listedPropertyImages: image,
    };

    axiosInstance
      .put("/admin/property/update-property", BODY, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // eslint-disable-next-line no-lone-blocks
          {
            !temp.state.data &&
              toast({
                title: "Successful!",
                description: "Property has been updated.",
                status: "success",
              });
          }
          navigate("/viewallproperties");
        } else {
        }
      })
      .catch((err) => console.error(err.message));
  };
  const imageDelete = (url) => {
    let pictureRef = storage.refFromURL(url);
    pictureRef
      .delete()
      .then(() => {
        setImageFromFirebase(
          imageFromFirebase.filter((image) => image !== url)
        );
        alert("Picture is deleted successfully!");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Box
        rounded={"lg"}
        px="5px"
        py="8px"
        pt="10px"
        bg={COLORS.greyEAEAEA}
        p="8"
      >
        <Stack spacing="5" pl="2">
          <Formik
            initialValues={initialValuess}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formikProps) => {
              return (
                <>
                  <Box bg={COLORS.white} p="7" borderRadius="6">
                    <Form>
                      <Stack w="45%" pb="4">
                        <Select
                          isDisabled={temp.state.data}
                          isMulti
                          options={categories.map((item) => {
                            return {
                              label: item.typeName,
                              value: item.id,
                            };
                          })}
                          onChange={setSelectCategories}
                        />
                      </Stack>
                      <HStack pb="3" w="85%" spacing="5">
                        <FormControl>
                          <FormLabel fontSize="22px" fontWeight="bold">
                            Property Name
                          </FormLabel>
                          <Input
                            variant="outline"
                            type="text"
                            name="complexName"
                            isDisabled={temp.state.data}
                            value={formikProps.values?.complexName}
                            required
                            onChange={(e) =>
                              formikProps.setFieldValue(
                                `complexName`,
                                e.target.value
                              )
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel fontSize="22px" fontWeight="bold">
                            Minimum Price
                          </FormLabel>
                          <NumberInput
                            name="minPrice"
                            variant="outline"
                            isDisabled={temp.state.data}
                            value={formikProps.values?.minPrice}
                            required
                            min={1}
                            onKeyDown={(e) =>
                              (e.key === "." ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onChange={(value) =>
                              formikProps.setFieldValue(`minPrice`, +value)
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </FormControl>
                        <FormControl>
                          <FormLabel fontSize="22px" fontWeight="bold">
                            Maximum Price
                          </FormLabel>
                          <NumberInput
                            variant="outline"
                            isDisabled={temp.state.data}
                            name="maxPrice"
                            value={formikProps.values?.maxPrice}
                            required
                            min={1}
                            onKeyDown={(e) =>
                              (e.key === "." ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onChange={(value) =>
                              formikProps.setFieldValue(`maxPrice`, +value)
                            }
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </FormControl>
                      </HStack>
                      <HStack spacing="5" w="25%">
                        <FormControl>
                          <FormLabel fontSize="22px" fontWeight="bold">
                            Parking Fee
                          </FormLabel>
                          <NumberInput
                            variant="outline"
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                            isDisabled={temp.state.data}
                            name="parkingFee"
                            value={formikProps.values?.parkingFee}
                            required
                            min={1}
                            onKeyDown={(e) =>
                              (e.key === "." ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onChange={(value) =>
                              formikProps.setFieldValue(`parkingFee`, +value)
                            }
                          >
                            <NumberInputField />
                          </NumberInput>
                        </FormControl>
                      </HStack>
                      <HStack w="55%">
                        <FormControl>
                          <FormLabel fontSize="22px" fontWeight="bold">
                            About
                          </FormLabel>
                          <Textarea
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                            isDisabled={temp.state.data}
                            name="description"
                            value={formikProps.values?.description}
                            onChange={(e) =>
                              formikProps.setFieldValue(
                                `description`,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      </HStack>
                      {!temp.state.data ? (
                        <AutoComplete
                          locationAddress={locationAddress}
                          setlocation={setLocationInfo}
                        />
                      ) : (
                        <HStack spacing="4" w="35%">
                          <FormControl>
                            <FormLabel fontSize="22px" fontWeight="bold">
                              Location
                            </FormLabel>
                            <Input isDisabled value={locationAddress} />
                          </FormControl>
                        </HStack>
                      )}
                      <Stack spacing="5">
                        {/* Checkbox section */}
                        <CheckboxSection isDisabled={temp.state.data} />
                        {/* Add photo component */}
                        <Text fontSize="22px" fontWeight="bold" pt="5">
                          Add photos
                        </Text>
                        <Box w="30%" pb="4">
                          {!temp.state.data && (
                            <HStack>
                              <UploadImage
                                imageFromFirebase={imageFromFirebase}
                                setImageFromFirebase={setImageFromFirebase}
                              />
                            </HStack>
                          )}
                          {image.length > 0 &&
                            image.map((item, keys) => {
                              return (
                                <>
                                  {!temp.state.data && (
                                    <SmallCloseIcon
                                      onClick={() => imageDelete(item.file)}
                                    />
                                  )}
                                  <Image
                                    key={keys}
                                    src={item.file}
                                    alt={`Uploaded Image ${keys}`}
                                  />
                                </>
                              );
                            })}
                        </Box>
                        <Box pb="5">
                          <Button
                            type="submit"
                            bg={COLORS.orange}
                            color={COLORS.white}
                            _hover={{
                              bg: COLORS.orange,
                            }}
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
                          >
                            {!temp.state.data ? "Save" : "Go Back"}
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  </Box>
                </>
              );
            }}
          </Formik>
        </Stack>
      </Box>
    </>
  );
};
