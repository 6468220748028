import React from "react";
import {
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { COLORS } from "../../color/color";
import logo from "../../assets/chillow-logo.svg";
import icon from "../../assets/icon.jpg";
import "./Header.css";

export const Header = (props) => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <>
      <Flex
        as="nav"
        wrap="wrap"
        padding={6}
        bg={COLORS.orange}
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
        {...props}
        justifyContent="space-between"
      >
        <Flex gap="20px" alignItems="center">
          <IconButton
            onClick={() => {
              navigate("/viewallproperties");
            }}
            colorScheme={COLORS.orange}
          >
            <Image boxSize="120" src={logo} alt="Chillow logo" height="10" />
          </IconButton>
          <NavLink activeclassname="active" to="/viewallproperties">
            View all Properties
          </NavLink>
          <NavLink activeclassname="active" to="/propertypartner">
            Add Property Partner
          </NavLink>
          <NavLink activeclassname="active" to="/notification">
            Notification
          </NavLink>
          <NavLink activeclassname="active" to="/entrata">
            Entrata
          </NavLink>
        </Flex>

        <Menu>
          <MenuButton
            as={Button}
            rounded={"full"}
            variant={"link"}
            cursor={"pointer"}
            minW={0}
          >
            <Avatar
              size={"sm"}
              src={
                icon
                //"https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
              }
            />
          </MenuButton>
          <MenuList>
            <MenuItem color={COLORS.black} onClick={handleLogOut}>
              Log Out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
};
