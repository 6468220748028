import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Header } from "../Header/Header";

const Layout = () => {
  return (
    <>
      <Header />
      <Box minH="82.3vh">
        <Outlet />
      </Box>
      <Box mt="auto">
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
