import {
  Flex,
  Box,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormLabel,
} from "@chakra-ui/react";
import { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import { COLORS } from "../color/color";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { axiosInstance } from "../constants/axiosInterceptor";
import { useUserStore } from "../store/user";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address format")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});
export const AuthPage = () => {
  const navigate = useNavigate();
  const { login } = useUserStore();

  const handleSubmit = (values) => {
    // navigate("/viewallproperties");
    const BODY = {
      email: values.email,
      password: values.password,
    };

    axiosInstance
      .post("/api/v1/chillow-admin/login", BODY)
      .then((res) => {
        // console.log("res", res);
        // login(res["data"].data); //jis type ka data ay ga us method sa assign karnay gay
        // localStorage.setItem("token", res["data"].data.token);
        localStorage.setItem("token", res.data.token);
        navigate("/viewallproperties");
      })
      .catch((err) => console.error("Login api Response error:", err));
  };

  return (
    <Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue(COLORS.grey50, COLORS.grey800)}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Admin Panel</Heading>
            <Text fontSize={"lg"} color={COLORS.grey600}>
              Enter Credential to Login
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue(COLORS.white, COLORS.grey700)}
            boxShadow={"lg"}
            p={8}
          >
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {(formikProps) => {
                return (
                  <Form>
                    <Stack spacing={4}>
                      <FormLabel fontSize="20px" fontWeight="bold">
                        Email
                      </FormLabel>
                      <Input
                        type="email"
                        name="email"
                        onChange={(e) =>
                          formikProps.setFieldValue(`email`, e.target.value)
                        }
                        className={`form-control ${
                          formikProps.touched.email && formikProps.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <label style={{ color: "red" }}>
                        {formikProps.errors.email}
                      </label>
                      <FormLabel fontSize="20px" fontWeight="bold">
                        Password
                      </FormLabel>
                      <Input
                        type="password"
                        name="password"
                        onChange={(e) =>
                          formikProps.setFieldValue(`password`, e.target.value)
                        }
                        className={`form-control ${
                          formikProps.touched.password &&
                          formikProps.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <label style={{ color: "red" }}>
                        {formikProps.errors.password}
                      </label>
                      <Stack spacing={10}>
                        <Button
                          type="submit"
                          bg={COLORS.orange}
                          color={COLORS.white}
                          _hover={{
                            bg: COLORS.orange,
                          }}
                        >
                          Sign in
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Stack>
      </Flex>
    </Fragment>
  );
};
