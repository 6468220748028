import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { COLORS } from "../../color/color";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  where,
} from "firebase/firestore";
import moment from "moment";
import { DeleteIcon } from "@chakra-ui/icons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from "html-react-parser";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageCompress from "quill-image-compress";

Quill.register("modules/imageCompress", ImageCompress);

const Notification = () => {
  const [title, setTitle] = useState("");
  const [getMessage, setGetMessage] = useState([]);
  const [idToDelete, setIdToDelete] = useState(-1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [stateData, setStateData] = useState("");

  const toast = useToast({
    duration: 2000,
    position: "top-right",
    isClosable: true,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const submit = async () => {
    await addDoc(collection(db, "NotificationCenter"), {
      title: title,
      message: stateData,
      type: "broadcast",
      sendTo: ["all"],
      seenBy: [],
      data: null,
      createdAt: serverTimestamp(),
    });
    setTitle("");
    setStateData("");
    toast({
      title: "Successful!",
      description: "Notification Sent.",
      status: "success",
    });
    fetchData();
  };

  const fetchData = () => {
    const q = query(
      collection(db, "NotificationCenter"),
      where("sendTo", "array-contains-any", ["all"]),
      orderBy("createdAt", "desc")
    );
    onSnapshot(q, (querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setGetMessage(newData);
    });
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "NotificationCenter", id));
    toast({
      title: "Successful!",
      description: "Notification deleted.",
      status: "success",
    });
    fetchData();
  };
  const handleChange = (data) => {
    setStateData(data);
  };

  return (
    <Flex>
      <Stack p="5" spacing="8">
        <FormControl>
          <FormLabel fontSize="22px" fontWeight="bold">
            Notification
          </FormLabel>
          <Input
            placeholder="Title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
          />
        </FormControl>
        <Box w="100%" pb="10">
          <ReactQuill
            style={{ width: "600px", height: "200px" }}
            theme="snow"
            value={stateData}
            onChange={handleChange}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "video"],
                ["clean"],
              ],
              clipboard: {
                matchVisual: true,
              },
              imageCompress: {
                quality: 0.8,
                maxWidth: 1500,
                maxHeight: 1500,
                imageType: "image/jpeg",
                debug: true,
              },
            }}
          />
        </Box>

        <Button
          type="submit"
          bg={COLORS.orange}
          color={COLORS.white}
          _hover={{
            bg: COLORS.orange,
          }}
          onClick={submit}
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
        >
          Send
        </Button>
      </Stack>

      <Divider orientation="vertical" height="80vh" border={"2px solid"} />
      <Stack py="7" px="10">
        {!!getMessage &&
          getMessage.map((item, keys) => (
            <>
              <Flex alignItems="center" key={keys} gap="3">
                <Box
                  borderWidth="3px"
                  borderRadius="lg"
                  p="4px 10px"
                  w="100%"
                  bg={COLORS.greyEAEAEA}
                >
                  <Text fontWeight="bold"> {item.title}</Text>
                  <Text>{parse(item.message)}</Text>
                  <Text pt="2" color="gray.600" fontSize="sm" textAlign="right">
                    {moment(new Date(item.createdAt?.seconds * 1000)).format(
                      "MMMM DD YYYY, hh:mm a"
                    )}
                  </Text>
                </Box>
                <DeleteIcon
                  height="30px"
                  width="20px"
                  _hover={{
                    color: COLORS.red,
                  }}
                  onClick={() => {
                    setIdToDelete(item.id);
                    onOpen();
                  }}
                />
              </Flex>
            </>
          ))}
      </Stack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Notification
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure to delete notification?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>

              <Button
                colorScheme="red"
                onClick={() => {
                  handleDelete(idToDelete);
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default Notification;
