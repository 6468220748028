import create from "zustand";

export const useUserStore = create((set) => ({
  user: undefined,

  login: (user) =>
    set((state) => ({
      user: user,
    })),

  logout: (user) =>
    set((state) => ({
      user: undefined,
    })),
}));
