import React from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { COLORS } from "../../color/color";

export default function Footer() {
  return (
    <Flex
      bg={COLORS.orange}
      width="100%"
      justifyContent="center"
      alignItems="center"
      direction="column"
      boxShadow="0 -1px 6px -1px rgba(0, 0, 0, 0.1)"
      padding={3}
      // left="0"
      // bottom="0"
      // position="fixed"
    >
      <HStack spacing={8} mb={4}>
        <Text color={COLORS.white}>Privacy</Text>
        <Text color={COLORS.white}>Terms of Use</Text>
      </HStack>
      <Flex width="100%" justifyContent="center" wrap="wrap">
        <Text width="50%" textAlign="center" color={COLORS.white} fontSize="sm">
          © 2022 GeekyBugs. All rights reserved
        </Text>
      </Flex>
    </Flex>
  );
}
