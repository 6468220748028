export const COLORS = {
  white: "white",
  grey50: "grey.50",
  grey800: "grey.800",
  grey700: "grey.700",
  grey600: "grey.600",
  blue400: "blue.400",
  orange: "#FB870C",
  black: "black",
  greyEAEAEA: "#EAEAEA",
  red: "red",
};
