import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, SearchIcon, ViewIcon } from "@chakra-ui/icons";
import { axiosInstance } from "../../constants/axiosInterceptor";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../color/color";

export default function ViewAllProperties() {
  useEffect(() => {
    loadProperties();
    setAddress();
  }, []);

  const navigate = useNavigate();
  const [disableOption] = useState(false);
  const [viewProperties, setViewProperties] = useState([]);
  const [search, setSearch] = useState("");
  const [idToDelete, setIdToDelete] = useState(-1);
  const toast = useToast({
    duration: 2000,
    position: "top-right",
    isClosable: true,
  });

  const handleSearch = () => {
    axiosInstance
      .get(`/admin/property/find-property-partner?name=${search}`)
      .then((res) => {
        setViewProperties(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    loadProperties();
  }, [!search]);

  const handleEdit = (id) => {
    navigate("/editproperty", {
      state: { data: disableOption, prop_id: id },
    });
  };
  const handleView = (id) => {
    navigate("/viewproperty", {
      state: { data: !disableOption, prop_id: id },
    });
  };
  const handleDelete = (id) => {
    if (id <= 0) return;
    axiosInstance
      .delete(`/admin/property/delete-property/?id=${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast({
          title: "Successful!",
          description: "Property has been Deleted.",
          status: "success",
        });
        loadProperties();
      })
      .catch((error) => {
        console.log("Delete", error);
      });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const loadProperties = () => {
    axiosInstance
      .get("/admin/property/view-properties", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setViewProperties(response.data);
      });
  };
  const setAddress = (object) => {
    for (const property in object) {
      if (
        property === "address" ||
        property === "city" ||
        property === "state" ||
        property === "country"
      ) {
        return object[property];
      }
    }
  };

  return (
    <>
      <Box>
        <Box pt="5" pb="10px" width="80" pl="10">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              placeholder="Search Property"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Box>
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th fontSize="13px">Property Name</Th>
                <Th fontSize="13px">Minimum Price</Th>
                <Th fontSize="13px">Maximum Price</Th>
                <Th fontSize="13px">Parking Fee</Th>
                <Th fontSize="13px">Location</Th>
                <Th fontSize="13px">View</Th>
                <Th fontSize="13px">Edit</Th>
                <Th fontSize="13px">Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {viewProperties.map((property) => (
                <Tr key={property.id}>
                  <Td>{property.complexName}</Td>
                  <Td>{property.minPrice}</Td>
                  <Td>{property.maxPrice}</Td>
                  <Td>{property.parkingFee}</Td>
                  <Td>{setAddress(property.location)}</Td>
                  <Td>
                    <ViewIcon
                      height="30px"
                      width="20px"
                      onClick={() => handleView(property.id)}
                    />
                  </Td>
                  <Td>
                    <EditIcon
                      height="30px"
                      width="20px"
                      onClick={() => handleEdit(property.id)}
                    />
                  </Td>
                  <Td>
                    <DeleteIcon
                      height="30px"
                      width="20px"
                      _hover={{
                        color: COLORS.red,
                      }}
                      onClick={() => {
                        setIdToDelete(property.id);
                        onOpen();
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Property
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure to delete property?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  colorScheme="red"
                  onClick={() => {
                    handleDelete(idToDelete);
                    onClose();
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </>
  );
}
