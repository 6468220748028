import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let auth = localStorage.getItem("token");
  // { token: true };

  return auth ? <Outlet /> : <Navigate to="/" />;
  // return auth.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
